import { withPrefix } from "gatsby";

export const createScript = (src, async = false, defer = true) => {
  const script = document.createElement("script");

  script.src = withPrefix(src);
  script.async = async;
  script.defer = defer;

  return script;
};
